import { createRouter, createWebHashHistory  } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
    {
        path: '/',
        redirect: '/dashboard'
    }, {
        path: "/",
        name: "Home",
        component: Home,
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                meta: {
                    title: '系统首页'
                },
                component: () => import(
                    /* webpackChunkName: "dashboard" */
                    "../views/Dashboard.vue")
            },
            {
                path: "/userManager",
                name: "userManager",
                meta: {
                    title: '用户管理'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    '../views/usermanager/userManager.vue')
            },
            {
                path: "/uploadPwd",
                name: "uploadPwd",
                meta: {
                    title: '修改密码'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    '../views/updPwd/index.vue')
            }, {
                path: "/updFullName",
                name: "updFullName",
                meta: {
                    title: '修改用户信息'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    '../views/updFullName/index.vue')
            },
            {
                path: "/noticeManager",
                name: "noticeManager",
                meta: {
                    title: '公告管理'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    '../views/notice/index.vue')
            },
            {
                path: "/consultManager",
                name: "consultManager",
                meta: {
                    title: '咨询管理'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    '../views/consult/index.vue')
            },
            {
                path: "/complaintManager",
                name: "complaintManager",
                meta: {
                    title: '投诉管理'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    '../views/complaint/index.vue')
            },
            {
                path: "/opinionManager",
                name: "opinionManager",
                meta: {
                    title: '意见反馈'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    '../views/opinion/index.vue')
            },
            {
                path: "/column/market",
                name: "market",
                meta: {
                    title: '市场行情'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    '../views/column/market.vue')
            },
            {
                path: "/column/news",
                name: "news",
                meta: {
                    title: '行业动态'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    '../views/column/news.vue')
            },
            {
                path: "/column/disease",
                name: "disease",
                meta: {
                    title: '技术交流'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    '../views/column/disease.vue')
            },
            {
                path: "/column/memeber",
                name: "memeber",
                meta: {
                    title: '地标企业'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    '../views/column/memeber.vue')
            },
            // {
            //     path: "/column/recommend",
            //     name: "recommend",
            //     meta: {
            //         title: '产品推荐'
            //     },
            //     component: () => import(
            //         /* webpackChunkName: "table" */
            //         '../views/column/recommend.vue')
            // },
            {
                path: "/column/supply",
                name: "supply",
                meta: {
                    title: '供应信息'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    '../views/column/supply.vue')
            },
            {
                path: "/column/breed",
                name: "breed",
                meta: {
                    title: '养殖分布'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    '../views/column/breed.vue')
            },
            {
                path: "/column/seek",
                name: "seek",
                meta: {
                    title: '求购信息'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    '../views/column/seek.vue')
            },
			{
			    path: "/column/company",
			    name: "company",
			    meta: {
			        title: '企业信息'
			    },
			    component: () => import(
			        /* webpackChunkName: "table" */
			        '../views/column/company.vue')
			},
			{
			    path: "/column/farmers",
			    name: "farmers",
			    meta: {
			        title: '养殖户信息'
			    },
			    component: () => import(
			        /* webpackChunkName: "table" */
			        '../views/column/farmers.vue')
			},
            {
                path: "/aboutUs",
                name: "aboutUs",
                meta: {
                    title: '关于我们'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    '../views/aboutUs/index.vue')
            },
            {
                path: "/SmsConfigsys",
                name: "smsconfigsys",
                meta: {
                    title: '短信模板'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    '../views/SmsConfigsys/smsconfigsys.vue')
            },
            {
                path: "/SmsTemplate",
                name: "SmsTemplate",
                meta: {
                    title: '短信模板管理'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    '../views/SmsTemplate/SmsTemplate.vue')
            },
            {
                path: "/help",
                name: "help",
                meta: {
                    title: '新手帮助'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    '../views/aboutUs/help.vue')
            },{
                path: "/icon",
                name: "icon",
                meta: {
                    title: '自定义图标'
                },
                component: () => import(
                    /* webpackChunkName: "icon" */
                    "../views/Icon.vue")
            },
            {
                path: '/404',
                name: '404',
                meta: {
                    title: '找不到页面'
                },
                component: () => import(/* webpackChunkName: "404" */
                    '../views/404.vue')
            }, {
                path: '/403',
                name: '403',
                meta: {
                    title: '没有权限'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/403.vue')
            }
        ]
    }, {
        path: "/login",
        name: "Login",
        meta: {
            title: '登录'
        },
        component: () => import(
            /* webpackChunkName: "login" */
            "../views/Login.vue")
    }
];

const router = createRouter({
    history: createWebHashHistory (process.env.BASE_URL),
    // base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    const role = localStorage.getItem('ms_username');
    if (!role && to.path !== '/login') {
        next('/login');
    } else if (to.meta.permission) {
        // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
        role === 'admin'
            ? next()
            : next('/403');
    } else {
        next();
    }
});

export default router;
<template>
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      :default-active="onRoutes"
      :collapse="collapse"
      background-color="#324157"
      text-color="#bfcbd9"
      active-text-color="#20a0ff"
      unique-opened
      router
    >
      <template v-for="item in items">
        <template v-if="item.subs">
          <el-submenu :index="item.index" :key="item.index">
            <template #title>
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs">
              <el-submenu
                v-if="subItem.subs"
                :index="subItem.index"
                :key="subItem.index"
              >
                <template #title>{{ subItem.title }}</template>
                <el-menu-item
                  v-for="(threeItem, i) in subItem.subs"
                  :key="i"
                  :index="threeItem.index"
                  >{{ threeItem.title }}</el-menu-item
                >
              </el-submenu>
              <el-menu-item
                v-else
                :index="subItem.index"
                :key="subItem.index"
                >{{ subItem.title }}</el-menu-item
              >
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index">
            <i :class="item.icon"></i>
            <template #title>{{ item.title }}</template>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
// import bus from "../common/bus";
export default {
  data() {
    return {
      items: [
        {
          icon: "el-icon-lx-home",
          index: "/dashboard",
          title: "系统首页",
        },
        {
          icon: "el-icon-s-custom",
          index: "/userManager",
          title: "用户管理",
        },
        {
          icon: "el-icon-lx-copy",
          index: "/noticeManager",
          title: "公告管理",
        },
        {
          icon: "el-icon-lx-comment",
          index: "/consultManager",
          title: "咨询管理",
        },
        {
          icon: "el-icon-lx-messagefill",
          index: "/complaintManager",
          title: "投诉管理",
        },
        {
          icon: "el-icon-lx-edit",
          index: "/opinionManager",
          title: "意见反馈",
        },
        {
          icon: "el-icon-lx-news",
          index: "news",
          title: "栏目管理",
          subs: [
            {
              index: "/column/news",
              title: "行业动态",
            },
            {
              index: "/column/disease",
              title: "技术交流",
            },
            {
              index: "/column/market",
              title: "市场行情",
            },
             {
              index: "/column/breed",
              title: "养殖分布",
            },
            {
              index: "/column/memeber",
              title: "地标企业",
            },
            //  {
            //   index: "/column/recommend",
            //   title: "产品推荐",
            // },
            {
              index: "/column/supply",
              title: "供应信息",
            },
            {
              index: "/column/seek",
              title: "求购信息",
            },
			{
			  index: "/column/company",
			  title: "企业信息",
			},
			/*{
			  index: "/column/farmers",
			  title: "养殖户信息",
			},*/
          ],
        },
         {
          icon: "el-icon-lx-copy",
          index: "/aboutUs",
          title: "关于我们",
        },
         {
          icon: "el-icon-lx-copy",
          index: "/help",
          title: "新手帮助",
        },
         {
          icon: "el-icon-lx-copy",
          index: "/SmsConfigsys",
          title: "短信参数配置",
        },
        
         {
          icon: "el-icon-lx-copy",
          index: "/SmsTemplate",
          title: "短信模板管理",
        },
        // {
        //     icon: "el-icon-rank",
        //     index: "6",
        //     title: "拖拽组件",
        //     subs: [
        //         {
        //             index: "drag",
        //             title: "拖拽列表"
        //         },
        //         {
        //             index: "dialog",
        //             title: "拖拽弹框"
        //         }
        //     ]
        // },
        // {
        //   icon: "el-icon-lx-global",
        //   index: "i18n",
        //   title: "国际化功能",
        // },
        // {
        //   icon: "el-icon-lx-warn",
        //   index: "7",
        //   title: "错误处理",
        //   subs: [
        //     {
        //       index: "permission",
        //       title: "权限测试",
        //     },
        //     {
        //       index: "404",
        //       title: "404页面",
        //     },
        //   ],
        // },
       
      ],
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path
    },
    collapse() {
      return this.$store.state.collapse;
    },
  },
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}
.sidebar > ul {
  height: 100%;
}
</style>

import request from '../utils/request';


//用户管理
export const getUser = query => {//用户列表
    return request({
        url: 'user/list',
        method: 'POST',
        data: query,
    });
};

export const addUser = query => {//添加用户
    return request({
        url: 'account/register',
        method: 'POST',
        data: query,
    });
};

export const editUser = query => {//修改用户
    return request({
        url: 'user/edit',
        method: 'POST',
        data: query,
    });
};

export const delUser = query => {//删除用户
    return request({
        url: 'user/remove',
        method: 'POST',
        data: query,
    });
};

export const ResetPassword = query => {//重置密码
    return request({
        url: 'user/ResetPassword',
        method: 'POST',
        data: query,
    });
};

export const modifyPassword = query => {//修改密码
    return request({
        url: 'account/modifypassword',
        method: 'POST',
        data: query,
    });
};


export const fullnameEdit = query => {//修改用户名
    return request({
        url: 'account/full_nameEdit',
        method: 'POST',
        data: query,
    });
};


export const ResetUser = query => {//设置授权用户
    return request({
        url: 'user/ResetUser',
        method: 'POST',
        data: query,
    });
};
export const AuditUser = query => {//设置授权用户
    return request({
        url: 'user/AuditUser',
        method: 'POST',
        data: query,
    });
};



export const updFullName = query => {//修改用户信息
    return request({
        url: 'user/personalEdit',
        method: 'POST',
        data: query,
    });
};


export const Login = (query) => {//登陆
    return request({
        url: 'account/Login',
        method: 'post',
        data: query,
    });
};



//公告管理
export const getNoticeList = (query) => {//公告列表
    return request({
        url: 'notice/List',
        method: 'post',
        data: query,
    });
};
export const noticeAdd = (query) => {//公告添加
    return request({
        url: 'notice/create',
        method: 'post',
        data: query,
    });
};
export const noticeEdit = (query) => {//公告修改 
    return request({
        url: 'notice/edit',
        method: 'post',
        data: query,
    });
};
export const getNoticeDetail = (query) => {//公告详情 notice/remove
    return request({
        url: 'notice/NoticeDetail',
        method: 'post',
        data: query,
    });
};
export const noticeRemove = (query) => {//公告删除
    return request({
        url: 'notice/remove',
        method: 'post',
        data: query,
    });
};


//咨询管理
export const getConsultList = (query) => {//咨询列表
    return request({
        url: 'consultcomplaint/consultList',
        method: 'post',
        data: query,
    });
};
export const consultRemove = (query) => {//咨询删除
    return request({
        url: 'consultcomplaint/consultRemove',
        method: 'post',
        data: query,
    });
};

//投诉管理
export const getComplainttList = (query) => {//投诉列表
    return request({
        url: 'consultcomplaint/complaintList',
        method: 'post',
        data: query,
    });
};
export const complaintRemove = (query) => {//投诉删除
    return request({
        url: 'consultcomplaint/complaintRemove',
        method: 'post',
        data: query,
    });
};

//意见反馈
export const getOpinionList = (query) => {//意见反馈列表
    return request({
        url: 'Opinion/opinionList',
        method: 'post',
        data: query,
    });
};
export const opinionRemove = (query) => {//意见反馈删除
    return request({
        url: 'Opinion/Remove',
        method: 'post',
        data: query,
    });
};

//所有栏目
export const getColumnList = (query) => {//栏目列表
    return request({
        url: 'Column/AdminList',
        method: 'post',
        data: query,
    });
};
export const Auditcolumn = query => {//栏目审核
    return request({
        url: 'Column/Auditcolumn',
        method: 'POST',
        data: query,
    });
};
export const getColumnDetails = (query) => {//栏目详情
    return request({
        url: 'Column/columnDetail',
        method: 'post',
        data: query,
    });
};

export const columnCreate = (query) => {//栏目添加
    return request({
        url: 'Column/Create',
        method: 'post',
        data: query,
    });
};

export const columnEdit= (query) => {//栏目修改
    return request({
        url: 'Column/Edit',
        method: 'post',
        data: query,
    });
};

export const columnRemove= (query) => {//栏目删除
    return request({
        url: 'Column/Remove',
        method: 'post',
        data: query,
    });
};



//获取市场行情列表和树状图
export const marketQuotation= (query) => {//栏目删除
    return request({
        url: 'Column/marketquotation',
        method: 'post',
        data: query,
    });
};

//获取评论列表
export const commentList= (query) => {//栏目删除
    return request({
        url: 'Comment/commentList',
        method: 'post',
        data: query,
    });
};

//删除评论
export const commentRemove= (query) => {//栏目删除
    return request({
        url: 'Comment/commentRemove',
        method: 'post',
        data: query,
    });
};

//获取关于我们/新手帮助
export const getDictionaries= (query) => {
    return request({
        url: 'dictionaries/Detail',
        method: 'post',
        data: query,
    });
};
//修改关于我们/新手帮助
export const editDictionaries= (query) => {
    return request({
        url: 'dictionaries/Edit',
        method: 'post',
        data: query,
    });
};



//获取短信模板详情
export const getSMSDetail= (query) => {
    return request({
        url: 'SmsConfigsys/Detail',
        method: 'post',
        data: query,
    });
};
//修改短信模板
export const EditSMSDetail= (query) => {
    return request({
        url: 'SmsConfigsys/Edit',
        method: 'post',
        data: query,
    });
};


//短信模板管理
export const getTemplate = query => {//短信模板列表
    return request({
        url: 'SmsTemplate/list',
        method: 'POST',
        data: query,
    });
};


export const addTemplate = query => {//添加短信模板
    return request({
        url: 'SmsTemplate/add',
        method: 'POST',
        data: query,
    });
};

export const editTemplate = query => {//修改短信模板
    return request({
        url: 'SmsTemplate/edit',
        method: 'POST',
        data: query,
    });
};

export const delTemplate = query => {//删除短信模板
    return request({
        url: 'SmsTemplate/remove',
        method: 'POST',
        data: query,
    });
};

export const fileUpload = (query) => {//上传图片
    return request({
        url: 'FileUpload/Upload',
        method: 'post',
        data: query,
    });
};
import axios from 'axios';
import router from '../router'

const service = axios.create({
    //baseURL: 'https://nddhy.org315.cn/api/',
	//baseURL: 'http://localhost:5458/',
	baseURL: "http://nddhy.uat.org315.cn/api/",
    timeout: 5000
});

service.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

service.interceptors.response.use(
    response => {
        if (response.data.Status === 200||response.data.Status === 400) {
            return response.data;
        }
        else if (response.data.Status === 201) {
            router.currentRoute.value.name !== 'login' &&
            router.replace({
              path: 'login',
              query: { redirect: router.currentRoute.value.name },
            })
        } else {
            
            Promise.reject();
        }
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);



export default service;

